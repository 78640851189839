import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import ScaleModal from './component/ScaleModal';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Map from './Map';
import Header from './Header/Header.js';
import Main from './Main/Main.js';
import Body from './Body/Body.js';
import Footer from './Footer/Footer.js';
import ApModal from './component/ApModal';
import DeleteModal from './component/DeleteModal.js';
import EditCollapse from './component/EditCollapse.js';
//import EditPage from './component/EditPage.js'
import ResponsiveMenu from './component/ResponsiveMenu.js';


ReactDOM.render(
  <React.StrictMode>

    <Main />
    <Header />
    <ResponsiveMenu />
    <ScaleModal />
    <ApModal />
    <DeleteModal />

    <Body />


  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
