
import "bootstrap/dist/css/bootstrap.min.css";





export default function DeleteModal() {


  return (
    <div>
      <div className="modal fade" id="DeleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
            </div>
            <div className="modal-body">
              Warning the deletion of this Ap will also lead to the deletion of aph associated with it.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelDelete">Cancel</button>
              <button type="button" className="btn btn-primary" id="confirmDelete">Confirm delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
