import "bootstrap/dist/css/bootstrap.min.css";
import { erease, edit, select } from "../Header/Header.js";



export default function ResponsiveMenu() {


  return (
    <div className="col-sm">
      <div className="collapse bg-dark" id="menucollapse">

        <div className="card card-body bg-dark">

          <a id='selector' className="btn btn-outline-light" href="#" onClick={select}><i className="bi bi-hand-index"></i> Selection mode</a>
          <a id='delete' className="btn btn-outline-light" href="#" onClick={erease}><i className="bi bi-trash"></i> Delete mode</a>
          <a id='edit' className="btn btn-outline-light" href="#" onClick={edit}><i className="bi bi-pen"></i> Edit mode</a>
          <a id='downloadbtn' className="btn btn-outline-light" href="#"><i className="bi bi-download"></i> Download map - json</a>



        </div>
      </div>
    </div>
  );

}