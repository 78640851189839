import 'bootstrap/dist/css/bootstrap.min.css';

import img from '../Image/ruller.png';




export var pxDistance;
export var meterDistance;


export function scalemap(map) {



  var rullerIcon = L.icon({
    iconUrl: img,
    iconSize: [50, 30],
    iconAnchor: [25, 30],
  });
  var marker1 = L.marker([0, 0], {
    draggable: 'true',
    icon: rullerIcon
  }).bindPopup("").addTo(map);
  var marker2 = L.marker([0, 0], {
    draggable: 'true',
    icon: rullerIcon
  }).bindPopup("").addTo(map);
  var ligne = L.polyline([]).addTo(map);

  marker1.on('dragend', findrag);
  marker2.on('dragend', findrag);
  marker1.on('drag', deplacement);
  marker2.on('drag', deplacement);


  function findrag(e) {

    var mark = e.target;
    var m1 = marker1.getLatLng();
    var m2 = marker2.getLatLng();

    var x1 = m1.lat;
    var y1 = m1.lng;
    var x2 = m2.lat;
    var y2 = m2.lng;

    pxDistance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));




    mark.getPopup().setContent("<button type='button' class='btn btn-success' data-toggle='modal' data-target='#modalscale' >confirm</button>");
    mark.openPopup();
    console.log("pxdistance = ", pxDistance)

  };

  function deplacement(e) {
    ligne.setLatLngs([marker1.getLatLng(), marker2.getLatLng()]);
  }


  document.getElementById('scalevalidate').onclick = function () {

    if (document.getElementById("scaleinput").value == "") {
      alert("You forgot to enter the distance between the two markers");
    } else {
      meterDistance = document.getElementById("scaleinput").value;
      $('#modalscale').modal('toggle');
      console.log("scale :", pxDistance, "px = ", meterDistance, "m");
      marker1.removeFrom(map);
      marker2.removeFrom(map);
      ligne.removeFrom(map);
      document.getElementById('inputimage').style.display = 'none'
      $('#collapseNav').collapse('toggle');

    }
  }
}