import './leaflet-heat.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import ap_png from '../Image/ap.png';
import aph_png from '../Image/aph.png';
import {
    aps
} from '../Body/Body';
import {
    latLng
} from 'leaflet';


export class Ap {


    aphs = []; //list of aph 
    aph_count = 0; //counter for aph
    dump = {}; //object for export json


    constructor(env, map, id, height, name, description, scale, degrees, latlng) {
        this.env = env;
        this.map = map;
        this.id = id;
        this.height = height;
        this.name = name;
        this.description = description;
        this.degrees = degrees;
        this.latlng = latlng;
        this.map_scale = {
            pxDistance: scale.pxDistance,
            meterDistance: scale.meterDistance
        }
        this.dump = {
            id,
            height,
            name,
            description,
            scale,
            degrees,
            ap: {},
            aph: [],
            latlng,
        }
    }

    getid() {
        return this.id
    }
    //create an ap(call :
    //create_marker
    //create_circle
    //setOnClickEvent)
    create(latlng) {
        let meterRadius = (this.height) * Math.tan((this.degrees * Math.PI / 180) / 2);
        let radius = ((meterRadius * this.map_scale.pxDistance) / this.map_scale.meterDistance);
        this.marker = this.create_marker(latlng);
        this.circle_big = this.create_circle(radius);
        this.setOnClickEvent(this.marker, this.circle_big);

    }

    //create an aph(call :
    //create_marker
    //create_circle
    //setOnClickEvent)

    create_ligne(latlng) {

        var pxDistanceLigne = Math.sqrt(Math.pow(latlng.lat - this.latlng.lat, 2) + Math.pow(latlng.lng - this.latlng.lng, 2))

        var meterDistanceLigne = (pxDistanceLigne * this.map_scale.meterDistance) / this.map_scale.pxDistance

        console.log("Distance ligne = ", meterDistanceLigne, 'm')

        console.log('ligne')
        var ligne = L.polyline([], {
            color: 'purple',
            weight: 4,
        }).bindPopup('')
        ligne.on('popupopen', function (e) {
            var popup = e.popup;
            popup.setContent('Length : ' + Math.round(meterDistanceLigne * 100) / 100 + ' m');
        });

        console.log('this ligne', this)

        ligne.setLatLngs([this.latlng, latlng]);
        console.log(this.latlng, latlng)
        ligne.addTo(this.map);

        return ligne
    }

    create_aph(latlng) {
        $("#collapseExample").collapse("hide")

        if (this.aph_count < 6) {
            let meterRadius = (this.height) * Math.tan((this.degrees * Math.PI / 180) / 2);
            let radius = ((meterRadius * this.map_scale.pxDistance) / this.map_scale.meterDistance);
            console.log('radius from ap.js = ', radius)
            let new_aph = {

                marker: this.create_marker(latlng, "Antenna Photonic"),
                circle_big: this.circle_big.addLatLng(latlng, radius),
                ligne: this.create_ligne(latlng),

            }

            console.log('new aph =', new_aph)
            this.aphs.push(new_aph);
            console.log(new_aph)
            console.log('taille', this.aphs.length)
            this.setOnClickEvent(new_aph.marker, new_aph.circle_big, new_aph.ligne);
            this.aph_count++;
            this.dump.aph.push({
                latlng
            })
        }
    }
    //create a marker
    create_marker(latlng, type = "Access Point") {
        var url;
        var size;
        if (type == "Access Point") {
            url = ap_png;
            size = [30, 30];
        } else {
            url = aph_png;
            size = [15, 15]
        }
        var icon = L.icon({
            iconUrl: url,
            iconSize: size,
        });

        let marker = L.marker([], {
            customId: this.id,
            customHeight: this.height,
            customLifiDeviceType: type,
            customName: this.name,
            customDescription: this.description,
            customDegrees: this.degrees,
            icon: icon,

        });


        marker.setLatLng(latlng);



        //create popup 
        var popup_content = "";
        if (type === "Access Point") {

            popup_content = document.createElement('div')

            var popup_name = document.createTextNode("Name : " + this.name);
            var text_br1 = document.createElement('br')
            var popup_id = document.createTextNode("Id : " + this.id);
            var text_br2 = document.createElement('br')
            var popup_height = document.createTextNode("Height : " + this.height + " m");
            var text_br3 = document.createElement('br')
            var popup_type = document.createTextNode("Type : " + type);
            var text_br4 = document.createElement('br')
            let button_content = document.createElement("button");
            button_content.id = "aphbtn" + this.id;
            button_content.innerHTML = "Add Aph";
            button_content.type = "button";
            button_content.className = "btn btn-success btn-sm";


            popup_content.appendChild(popup_name)
            popup_content.appendChild(text_br1)
            popup_content.appendChild(popup_id)
            popup_content.appendChild(text_br2)
            popup_content.appendChild(popup_height)
            popup_content.appendChild(text_br3)
            popup_content.appendChild(popup_type)
            popup_content.appendChild(text_br4)
            popup_content.appendChild(button_content)

        } else if (type === "Antenna Photonic") {
            popup_content = document.createElement('div')

            var popup_type = document.createTextNode("Type : " + type);

            popup_content.appendChild(popup_type)

        }

        marker.bindPopup(
            popup_content

        ).openPopup();
        //add marker to map
        marker.addTo(this.map);
        return marker;
    }
    //create a circle
    create_circle(r) {

        let circle = L.heatLayer([], {
            radius: r,
            blur: 15
        })

        console.log('create circle');
        //circle.setLatLng(latlng);
        //L.circle([latlng.lat, latlng.lng], { radius: radius }).addTo(this.map)
        circle.addTo(this.map);
        return circle;

    }
    //choose with env.mode : editmarker || deleteMarker
    setOnClickEvent(marker, circle_big, ligne) {

        marker.on('click', function () {

            //var ap = this.options.custumAp;
            if (this.env.env.mode === "delete") {

                this.deleteMarker(marker, circle_big, ligne);

            } else if (this.env.env.mode === "edit") {

                this.editmarker(marker, circle_big);

            }

        }.bind(this))

    }
    //edit a marker
    editmarker() {
        console.log("edit")
        console.log(this.marker)
        var myMarker = this.marker
        var detailId = myMarker.options.customId;
        var detailHeight = myMarker.options.customHeight;
        var detailNom = myMarker.options.customName;
        var detailroom = myMarker.options.customDegrees;

        //set actual properties from marker in collapse's input
        document.getElementById("detailinputid").value = detailId;
        document.getElementById("detailinputheight").value = detailHeight;
        document.getElementById("detailinputname").value = detailNom;
        document.getElementById("detailinputroom").value = detailroom;
        //when button validate is click 
        document.getElementById('validateedit').onclick = function () {
            //replace actual properties from marker by collapse's input
            myMarker.options.customName = document.getElementById("detailinputname").value;
            myMarker.options.customHeight = document.getElementById("detailinputheight").value;
            myMarker.options.customDescription = document.getElementById("detailinputroom").value;
            myMarker.options.customDegrees = document.getElementById('detailinputroom').value;

            var popup_content = document.createElement('div')

            var popup_name = document.createTextNode("Name : " + myMarker.options.customName);
            var text_br1 = document.createElement('br')
            var popup_id = document.createTextNode("Id : " + detailId);
            var text_br2 = document.createElement('br')
            var popup_height = document.createTextNode("Height : " + myMarker.options.customHeight + " m");
            var text_br3 = document.createElement('br')
            var popup_type = document.createTextNode("Type : Access Point");
            var text_br4 = document.createElement('br')
            let button_content = document.createElement("button");
            button_content.id = "aphbtn" + this.id;
            button_content.innerHTML = "Add Aph";
            button_content.type = "button";
            button_content.className = "btn btn-success btn-sm";

            popup_content.appendChild(popup_name)
            popup_content.appendChild(text_br1)
            popup_content.appendChild(popup_id)
            popup_content.appendChild(text_br2)
            popup_content.appendChild(popup_height)
            popup_content.appendChild(text_br3)
            popup_content.appendChild(popup_type)
            popup_content.appendChild(text_br4)
            popup_content.appendChild(button_content)
            //set new popup to the marker 
            myMarker.getPopup().setContent(
                popup_content
            ).openPopup();
            //find circle wehre his customid is like marker's custom id
            var meterRadius = (myMarker.options.customHeight) * Math.tan((myMarker.options.customDegrees * Math.PI / 180) / 2);

            this.circle_big.setOptions({
                radius: (meterRadius * this.map_scale.pxDistance) / this.map_scale.meterDistance
            })

        }.bind(this)
    }
    //delete a marker
    deleteMarker(marker, circle_big, ligne) {
        console.log("delete")

        console.log(marker)

        if (marker.options.customLifiDeviceType == "Antenna Photonic") {

            circle_big._latlngs.forEach(element => {

                if (element === marker._latlng) {

                    circle_big._latlngs.splice(circle_big._latlngs.indexOf(element), 1);

                    circle_big.redraw()
                }
            });

            this.aphs.forEach(element => {

                if (element.marker._latlng == marker._latlng) {

                    this.aphs.splice(this.aphs.indexOf(element), 1)

                    this.aph_count--;
                }
                ligne.removeFrom(this.map)
            })
            marker.removeFrom(this.map);
        } else {
            $("#DeleteModal").modal("toggle")

            $("body").on('click', '#confirmDelete', function () {
                this.aphs.forEach(element => {

                    element.ligne.removeFrom(this.map)

                    element.marker.removeFrom(this.map)
                });

                this.aph_count = 0;

                console.log(marker)
                marker.removeFrom(this.map);
                circle_big.removeFrom(this.map)

                console.log('aps ', aps)

                console.log(this.id)

                aps.forEach(element => {

                    if (element.id === this.id) {
                        console.log('element', element)
                        aps.splice(aps.indexOf(element), 1);
                    }
                });
                console.log('new list ', aps)

                $("#DeleteModal").modal("toggle")
            }.bind(this))
        }
    }
    //return dump for json
    dump_Ap() {
        return this.dump;
    }
}