
import 'bootstrap/dist/css/bootstrap.min.css';
import { Ap } from '../function/Ap.js'
import { scalemap } from '../function/Scale.js';
import { env, nom, height, degrees } from '../Header/Header.js';
import { pxDistance, meterDistance } from '../function/Scale.js';
import 'leaflet-simple-map-screenshoter';
import camera from '../Image/camera.svg';
import { EditPage } from '../component/EditPage.js';
import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import logo_graph from '../Image/logo_graph.png'
//import { TodoList, setListApFunc } from '../component/ListPage.js';



export var map;
var i = 0,
  ap_focus;
var markerScale
var latMarkerScale;
var lngMarkerScale;
export var aps = [];


export function initialize(output, widthimg, heightimg) {


  console.log(widthimg, heightimg)
  //get button from the nav bar 
  /*var lightbtn = document.getElementById('lightbtn');
  var selectorbtn = document.getElementById('selectorbtn');
  var deletebtn = document.getElementById('deletebtn');
  var editbtn = document.getElementById('editbtn');*/
  //create map




  /**/

  map = L.map('map', {
    crs: L.CRS.Simple,
    maxZoom: 4


  });

  //get and set image to the map


  var bounds = [
    [0, 0],
    [heightimg, widthimg]
    //[img.height, img.width]
  ];
  console.log(bounds)
  L.imageOverlay(output, bounds).addTo(map);
  L.simpleMapScreenshoter({
    screenName: 'Lifi_map',
    iconUrl: camera,

  }).addTo(map)
  map.fitBounds(bounds);

  scalemap(map);

  map.on('click', placerAp)
}

function placerAp(e) {


  console.log('placerap')

  console.log(e)

  if ({ env }.env.mode === "ap") {



    let myAp = new Ap({ env }, map, i, { height }.height, { nom }.nom, '', {
      pxDistance,
      meterDistance
    }, { degrees }.degrees, e.latlng);

    myAp.create(e.latlng)

    // Get the on click event from the btn create on the ap marker
    $("body").on('click', '#aphbtn' + myAp.id, function () {

      console.log('createaph')
      create_aph(myAp.id);
    })

    aps.push(myAp)

    //i for id++
    i++


  } else if ({ env }.env.mode === "aph") {
    console.log("if mode = aph")
    ap_focus.create_aph(e.latlng);


  }
}
//function to create an aph
function create_aph(apIndex) {

  ap_focus = aps.find(ap => {
    return ap.id === apIndex
  });

  env.mode = "aph";
}
function create_markerScale() {

  env.mode = 'select'

  console.log('click')
  console.log(markerScale);
  if (markerScale) {
    console.log('remove');
    markerScale.removeFrom(map)
    markerScale = undefined

  } else {
    console.log('create');
    var icon = L.icon({
      iconUrl: logo_graph,
      iconSize: [50, 50],
    });
    markerScale = L.marker([0, 0], {
      draggable: true,
      icon: icon
    }).addTo(map);


    markerScale.on('dragend', function (e) {
      console.log('lat ', markerScale.getLatLng().lat)
      console.log('lng', markerScale.getLatLng().lng)
      latMarkerScale = markerScale.getLatLng().lat;
      lngMarkerScale = markerScale.getLatLng().lng;
    })
  }

}
export default function Body() {

  const [Listap, setListAp] = useState();


  let displayAp = aps.map(element => {

    var meterDistance = element.map_scale.meterDistance
    var pxDistance = element.map_scale.pxDistance

    let displayAph = element.aphs.map(element => {

      var pxDistanceLat = Math.sqrt(Math.pow(latMarkerScale - element.marker._latlng.lat, 2))
      var pxDistanceLng = Math.sqrt(Math.pow(lngMarkerScale - element.marker._latlng.lng, 2))

      var meterDistanceLng = Math.round((pxDistanceLng * meterDistance) / pxDistance * 100) / 100;

      var meterDistanceLat = Math.round((pxDistanceLat * meterDistance) / pxDistance * 100) / 100;

      console.log('aphs coord ', meterDistanceLng, meterDistanceLat);


      return (
        <li className='list-group-item'>
          x: {meterDistanceLng} m | y: {meterDistanceLat} m
        </li>
      )
    })

    return (
      <li className="list-group-item" key={element.id}>
        <div className='row'>
          <div className='col-4'>
            {element.marker.options.customName}
            <p style={{ backgroundColor: 'green', float: 'right', borderRadius: '10px', padding: '5px', color: 'white' }}>
              Aph : {element.aph_count}
            </p>
          </div>
          <div className='col-8'>
            <ul className='list-group'>
              {displayAph}
            </ul>
          </div>


        </div>

      </li>

    );
  })


  return (


    <div id='globalpagebody' style={{ display: 'none' }} className='container' onClick={setListAp}>
      <div className='row' style={{ margin: "0px" }}>
        <div className='col-8' style={{ border: '1px solid grey', borderRadius: '10px', height: `${window.innerHeight - 54}px` }} >
          <div id='map' style={{ height: `100%` }}></div>

        </div>

        <div className='col-4' style={{ border: '1px solid grey', borderRadius: '10px', maxHeight: `${window.innerHeight - 54}px` }}>
          <div className='row ' style={{ height: '50%', overflow: 'auto', }}>

            <ul className="list-group" >
              {displayAp}
            </ul>

          </div>

          <hr />

          <div className='row' style={{ height: '50%' }}>
            <div className='row'>
              <div className='col-6'>
                <p>Total of Ap :{aps.length}</p>
              </div>
              <div className='col-6'>
                <button type="button" className="btn btn-outline-primary" onClick={create_markerScale}>Toggle measure</button>
              </div>
            </div>
            <div className='col-1'>
              <canvas id='Mycanvas'></canvas>
            </div>
            <div className='col-11'>
              <EditPage />
            </div>

          </div>


        </div>
      </div>
    </div >


  );

}


