
import "bootstrap/dist/css/bootstrap.min.css";




export default function scalemodal() {


  return (
    <div className="modal fade" id="modalscale" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Scale setting</h5>

          </div>
          <div className="modal-body">
            <p>Distance between the two markers : </p>
            <input id="scaleinput" type="text" className='form-control' placeholder='Distance in m'
              aria-label="Recipient's username" aria-describedby='basic-addon2' />

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button id='scalevalidate' type="button" className="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  );

}
