
import "bootstrap/dist/css/bootstrap.min.css";




export default function apmodal() {


  return (
    <div className="modal fade" id="apmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Advanced Settings</h5>
          </div>
          <div className="modal-body">

            <div className='input-group' >
              <form style={{ width: '100%' }}>
                <div className="form-group">
                  <p><u>Name of Ap: </u></p>
                  <input id="name" className='form-control' placeholder='Name of Ap' aria-label="Recipient's username" aria-describedby='basic-addon2' />
                </div>
                <hr />
                <div className="form-group">
                  <p><u>Height of the celing: </u></p>
                  <input id="heightinput" className='form-control' placeholder='Height of light(m)' aria-label="Recipient's username" aria-describedby='basic-addon2' />
                </div>
                <hr />
                <div className="form-group">
                  <p><u>Angle degrees of Ap: </u></p>
                  <input id="degrees" defaultValue="90" className='form-control' placeholder='Angle degrees of Ap' aria-label="Recipient's username" aria-describedby='basic-addon2' />
                </div>
              </form>
            </div>




          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button id="validate" type="button" className="btn btn-primary" data-dismiss="modal" >validate</button>
          </div>

        </div>
      </div>
    </div>
  );

}
