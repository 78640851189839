import 'bootstrap/dist/css/bootstrap.min.css';


function onClick() {
  document.getElementById('newproject');
  var firstpage = document.getElementById('firstpage');
  firstpage.style.display = 'none'
  var globalpageheader = document.getElementById('globalpageheader');
  globalpageheader.style.display = 'block'
  var globalpagebody = document.getElementById('globalpagebody');
  globalpagebody.style.display = 'block'
  //var globalpagefooter = document.getElementById('globalpagefooter');
  //globalpagefooter.style.display = 'block'
  //$('#tutoModal').modal('toggle')

}


export default function main() {

  return (

    <div id='firstpage'>
      <div className="d-flex justify-content-center">
        <img src="./oledcomm.png" />
      </div>
      <div className="d-flex justify-content-center startchoose">
        <div id='startchoose' style={{ border: '1px solid' }}>
          <nav className="navbar navbar-dark bg-dark">
            <span className="navbar-text" style={{ color: 'white' }}>
              Welcome to the integration tool
            </span>
          </nav>
          <br />
          <div id='selectproject' className="d-flex flex-column">
            <div className="p-2"><button style={{ width: '100%' }} id="newproject" type="button"
              className="btn btn-lg btn-light" onClick={onClick}><i style={{ float: 'left' }}
                className="bi bi-plus-square"></i>
              <p style={{ float: 'left', marginLeft: '10px' }}> Start a New Project</p>
            </button>
            </div>
            <hr />
            <div className="p-2"><button style={{ width: '100%' }} type="button" className="btn btn-lg btn-light">
              <i style={{ float: 'left' }} className="bi bi-upload"></i>
              <input id='inputproject' style={{ width: '300px' }} type="file" className="custom"></input>
              <p style={{ float: 'left', marginLeft: '10px' }}> Import an existing file (json)</p>
            </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>

  );

}