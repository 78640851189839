
import "bootstrap/dist/css/bootstrap.min.css";
import { aps } from "../Body/Body";




export function EditPage() {


  return (
    <div style={{ margin: 'auto' }}>

      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">ID : </span>
        </div>
        <input type="text" className="form-control" id="detailinputid" placeholder='id' aria-describedby="basic-addon3" disabled />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">Name : </span>
        </div>
        <input type="text" className="form-control" id="detailinputname" placeholder='name' aria-describedby="basic-addon3" />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">Height : </span>
        </div>
        <input type="text" className="form-control" id="detailinputheight" placeholder='height' aria-describedby="basic-addon3" />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">degrees : </span>
        </div>
        <input type="text" className="form-control" id="detailinputroom" placeholder='Optional' aria-describedby="basic-addon3" />
      </div>
      <button id="validateedit" className="btn btn-outline-success w-100px m-1" style={{ float: 'right' }}>validate</button>
      <button className="btn btn-outline-secondary w-40px m-1" data-toggle="collapse" data-target="#EditCollapse" style={{ float: 'right' }}><i className="bi bi-x-circle" ></i></button>
    </div >
  );

}
