import 'bootstrap/dist/css/bootstrap.min.css';
import { initialize } from '../Body/Body';
import React from "react";
import '../css/style.css';
import logo from '../Image/logo_oledcomm.png'

export var env = {};
export var nom;
export var height;
export var degrees;
var widthimg;
var heightimg;



export function edit() {

  env.mode = "edit";
  document.getElementById('ap').className = "btn btn-outline-light"
  document.getElementById('selector').className = "btn btn-outline-light"
  document.getElementById('edit').className = "btn btn-outline-light active"
  document.getElementById('delete').className = "btn btn-outline-light"



}
export function erease() {

  env.mode = "delete";
  document.getElementById('ap').className = "btn btn-outline-light"
  document.getElementById('selector').className = "btn btn-outline-light"
  document.getElementById('edit').className = "btn btn-outline-light"
  document.getElementById('delete').className = "btn btn-outline-light active"


}

export function select() {

  env.mode = 'select'
  document.getElementById('ap').className = "btn btn-outline-light"
  document.getElementById('selector').className = "btn btn-outline-light active"
  document.getElementById('edit').className = "btn btn-outline-light"
  document.getElementById('delete').className = "btn btn-outline-light"


}

function onChange(event) {



  fetch(event.target.files[0].name).then(function (response) {

    return response.blob();
  }).then(async function (myBlob) {
    var reader = new FileReader();
    reader.readAsDataURL(myBlob);
    reader.onloadend = function () {
      var base64String = reader.result;


      console.log(event.target.files[0])

      var img = document.createElement('img');
      //on met le contenu du fichier dans l'image
      img.src = URL.createObjectURL(event.target.files[0]);
      // on ne l'affiche pas
      img.style.display = 'none';
      // la taille ne sera dispo que losque l'image sera chargée
      img.onload = function () {
        //on affiche la taille

        widthimg = this.width;
        heightimg = this.height;
        console.log(widthimg, heightimg)
        //on a plus besoin de l'élément IMG
        document.body.removeChild(this);
        var objectURL = URL.createObjectURL(event.target.files[0]);

        initialize(objectURL, widthimg, heightimg)
      }
      document.body.appendChild(img);



    }

  });




}

function ap() {
  //change the icon from the button
  /*lightbtn.setAttribute('class', 'bi bi-lightbulb-fill');
  selectorbtn.setAttribute('class', 'bi bi-hand-index');
  editbtn.setAttribute('class', 'bi bi-pen');
  deletebtn.setAttribute('class', 'bi bi-trash');
  //set back ground button activate
  document.getElementById("ap").className = "navbar-brand btn btn-dark active";
  document.getElementById("selector").className = "navbar-brand btn btn-dark";
  document.getElementById("edit").className = "navbar-brand btn btn-dark";
  document.getElementById("delete").className = "navbar-brand btn btn-dark";*/
  //set mode to ap
  env.mode = "ap";
  $("#EditCollapse").collapse("hide")
  $("#apmodal").modal("toggle")

  //when button validate is click
  document.getElementById('validate').onclick = function () {
    //get information nom,height,value,degrees from advanced setting
    nom = document.getElementById("name").value;
    height = document.getElementById("heightinput").value;
    degrees = document.getElementById("degrees").value;


    document.getElementById('ap').className = "btn btn-outline-light active"
    document.getElementById('selector').className = "btn btn-outline-light"
    document.getElementById('edit').className = "btn btn-outline-light"
    document.getElementById('delete').className = "btn btn-outline-light"



    console.log(nom, height, degrees)

  }
}

export default function Header() {





  return (



    <div id='globalpageheader' style={{ display: 'none' }}>

      <nav className="navbar bg-dark navbar-dark btn-dark">
        <div className="collapse navbar" id="collapseNav">
          <a className="navbar-brand" href="#">
            <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="" />

          </a>

          <a id='ap' className="btn btn-outline-light" onClick={ap} href="#"><i className="bi bi-lightbulb"></i> Place Lifi device</a>
          <a id='selector' className="btn btn-outline-light" onClick={select} href="#"><i className="bi bi-hand-index"></i> Selection mode</a>
          <a id='delete' className="btn btn-outline-light" onClick={erease} href="#"><i className="bi bi-trash"></i> Delete mode</a>
          <a id='edit' className="btn btn-outline-light" onClick={edit} href="#"><i className="bi bi-pen"></i> Edit mode</a>
          <a id='downloadbtn' className="btn btn-outline-light" href="#"><i className="bi bi-download"></i>Download map - json</a>
        </div>






        <input id='inputimage' className="form-control" style={{ width: "300px" }} type="file" accept="image/*" onChange={onChange} />

        <a id='menu' className="btn btn-outline-light" data-toggle="collapse" data-target='#menucollapse' aria-expanded="false" aria-controls="menucollapse" href="#"><i className="bi bi-list"></i></a>






      </nav >




    </div >








  );

}

/* <a id="ap" className="navbar-brand btn btn-dark ap" href="#" onClick={ap}>Place Lifi device <i id='lightbtn'
            className="bi bi-lightbulb"></i></a>

          <a id="selector" className="navbar-brand btn btn-dark" href="#">Selection mode <i id='selectorbtn'
            className="bi bi-hand-index"></i></a>
          <a id="delete" className="navbar-brand btn btn-dark" href="#" onClick={erease}>Delete mode <i id='deletebtn'
            className="bi bi-trash"></i></a>

          <a id="edit" className="navbar-brand btn btn-dark" href="#" data-toggle="collapse" data-target="#EditCollapse"
            aria-expanded="false" aria-controls="EditCollapse" onClick={edit}>Edit mode <i id='editbtn' className="bi bi-pen"></i> </a>

          <a id="downloadbtn" className="navbar-brand btn btn-dark" href="#">Download Map - Json <i id='deletebtn'
            className="bi bi-download"></i></a>*/