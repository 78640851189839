
import './App.css';
import * as All from 'react-leaflet';
import './leaflet-heat.js';




function App() {

  const center = [51.505, -0.09]

  const polyline = [
    [51.505, -0.09],
    [51.51, -0.1],
    [51.51, -0.12],
  ]
  
  const multiPolyline = [
    [
      [51.5, -0.1],
      [51.5, -0.12],
      [51.52, -0.12],
    ],
    [
      [51.5, -0.05],
      [51.5, -0.06],
      [51.52, -0.06],
    ],
  ]
  
  const polygon = [
    [51.515, -0.09],
    [51.52, -0.1],
    [51.52, -0.12],
  ]
  
  const multiPolygon = [
    [
      [51.51, -0.12],
      [51.51, -0.13],
      [51.53, -0.13],
    ],
    [
      [51.51, -0.05],
      [51.51, -0.07],
      [51.53, -0.07],
    ],
  ]
  
  const rectangle = [
    [51.49, -0.08],
    [51.5, -0.06],
  ]
  
  const fillBlueOptions = { fillColor: 'blue' }
  const blackOptions = { color: 'black' }
  const limeOptions = { color: 'lime' }
  const purpleOptions = { color: 'purple' }
  const redOptions = { color: 'red' }
  console.log('app')

  return (
    
    <All.MapContainer center={center} zoom={13} scrollWheelZoom={true}>
    <All.TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <All.Circle center={center} pathOptions={fillBlueOptions} radius={200} />
    <All.Circle center={[51.51, -0.12]} pathOptions={redOptions} radius={200}>
      <All.Popup>Popup in CircleMarker</All.Popup>
    </All.Circle>
    <All.Polyline pathOptions={limeOptions} positions={polyline} />
    <All.Polyline pathOptions={limeOptions} positions={multiPolyline} />
    <All.Polygon pathOptions={purpleOptions} positions={polygon} />
    <All.Polygon pathOptions={purpleOptions} positions={multiPolygon} />
    <All.Rectangle bounds={rectangle} pathOptions={blackOptions} />
    <All.Marker position={[51.51, -0.09]}>
      <All.Popup>Popup for Marker</All.Popup>
      <All.Tooltip>Tooltip for Marker</All.Tooltip>
    </All.Marker>
  </All.MapContainer>
      



    
  );
 
}

export default App;
